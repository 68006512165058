export default () => ( 
{
    inView: false,
    goingUp: true,
    currentPosition: 0,
    newPosition: 0,

    getCurrentPosition() {
        this.currentPosition = window.scrollY;
    },

    detectScroll() {
        if (this.inView) {
            this.newPosition = window.scrollY;
            
            if (this.newPosition < this.currentPosition) {
                this.goingUp = true;
            } else {
                this.goingUp = false;
            }
        }
    },
});